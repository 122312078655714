import React, {createContext, useContext, useEffect, useState} from 'react';
import {useRtClient} from "./rtClientContext";
import useIntervalHook from "../hooks/UseIntervalHook";
import useUsersMe from "../hooks/UseUsersMe";
import {useElectron} from "./electronContext";

const CurrentUserContext = createContext({
    currentUser: null,
});

export const useCurrentUser = () => {
    return useContext(CurrentUserContext);
}

export const CurrentUserProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const {client, isReady} = useRtClient();
    const state = useIntervalHook(useUsersMe, 60);
    const { handleCurrentUserRefresh } = useElectron();

    const refreshCurrentUser = (event) => {
        if (client && isReady) {
            client.users.me().then((state) => {
                if (state.user) {
                    setCurrentUser(state.user);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        if (state.currentUser) {
            setCurrentUser(state.currentUser);
        }
    }, [client, isReady, state, state.currentUser]);

    useEffect(() => {
        if (client && isReady) {
            handleCurrentUserRefresh(refreshCurrentUser);
        }
    }, [handleCurrentUserRefresh, client, isReady]);

    return (
        <CurrentUserContext.Provider value={{currentUser: currentUser}}>
            {children}
        </CurrentUserContext.Provider>
    );
}