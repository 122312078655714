import {useRtClient} from "../contexts/rtClientContext";
import React, {useEffect, useState} from "react";

const useUsers = (reload) => {
    const {client, isReady} = useRtClient();
    const [state, setState] = useState({
        users: null,
        isLoading: true,
        isError: false,
    });

    useEffect(() => {
        if (isReady && client) {
            const abortController = new AbortController();

            const fetchUsers = async () => {
                try {
                    const users = await client.users.getAll({signal: abortController.signal});
                    setState({
                        users: users.users,
                        isLoading: false,
                        isError: false,
                    });
                } catch (err) {
                    if (err.name !== "AbortError") {
                        setState({
                            users: null,
                            isLoading: false,
                            isError: true,
                        });
                    }
                }
            };

            fetchUsers();

            return () => {
                abortController.abort();
            };
        }
    }, [client, isReady, reload]);

    return state;
}

export default useUsers;