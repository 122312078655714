import {useRtClient} from "../contexts/rtClientContext";
import React, {useEffect, useState} from "react";
const useUserImages = (userId) => {
    const {client, isReady} = useRtClient();
    const [state, setState] = useState({
        images: null,
        isLoading: true,
        isError: false,
    });

    useEffect(() => {
        if (isReady && client) {
            const abortController = new AbortController();
            const fetchUserImages = async () => {
                try {
                    const images = await client.users.images(userId, {signal: abortController.signal});
                    setState({
                        images: images.images,
                        isLoading: false,
                        isError: false,
                    });
                } catch (err) {
                    if (err.name !== "AbortError") {
                        setState({
                            images: null,
                            isLoading: false,
                            isError: true,
                        });
                    }
                }
            };
            // Intentionally ignoring the promise
            fetchUserImages();

            return () => {
                abortController.abort();
            };
        }
    }, [client, isReady]);

    return state;
}

export default useUserImages;