import React, {createContext, useContext, useEffect, useState} from 'react';

const ElectronContext = createContext({
    toggleImageGallery: () => {},
    openCreateNewImage: () => {},
    switchToSignIn: () => {},
    switchToApp: () => {},
    requestCurrentUserRefresh: () => {},
    sendAccessToken: () => {},
    requestNewImage: null,
    selectImage: null,
    imageCreationStatus: { status: "ready" },
    changeImageCreationStatus: () => {},
    openImageSelectionView: () => {},
});

export const useElectron = () => {
    return useContext(ElectronContext);
}

export const ElectronProvider = ({ children }) => {
    const [imageCreationStatus, setImageCreationStatus] = useState(null);

    useEffect(() => {
        if (window?.rtAPI?.handleImageCreationStatusUpdate) {
            window?.rtAPI?.handleImageCreationStatusUpdate((event, status) => {
                console.log("Event: ", event);
                setImageCreationStatus(status);
            });
        }
    }, []);

    const toggleImageGallery = (user) => {
        if (window?.rtAPI?.toggleImageGallery) {
            window?.rtAPI?.toggleImageGallery(user);
        }
    }

    const openCreateNewImage = () => {
        if (window?.rtAPI?.openNewImage) {
            window?.rtAPI?.openNewImage();
        }
    }

    const switchToSignIn = () => {
        if (window?.rtAPI?.switchToSignIn) {
            window?.rtAPI?.switchToSignIn();
        }
    }

    const switchToApp = () => {
        if (window?.rtAPI?.switchToApp) {
            window?.rtAPI?.switchToApp();
        }
    }

    const requestCurrentUserRefresh = () => {
        if (window?.rtAPI?.requestCurrentUserRefresh) {
            window?.rtAPI?.requestCurrentUserRefresh();
        }
    }

    const handleCurrentUserRefresh = (callback) => {
        if (window?.rtAPI?.handleCurrentUserRefresh) {
            window?.rtAPI?.handleCurrentUserRefresh(callback);
        }
    }

    const sendAccessToken = (token) => {
        if (window?.rtAPI?.sendAccessToken) {
            window?.rtAPI?.sendAccessToken(token);
        }
    }

    const requestNewImage = (data) => {
        if (window?.rtAPI?.requestNewImage) {
            window?.rtAPI?.requestNewImage(data);
        }
    }

    const selectImage = (image) => {
        if (window?.rtAPI?.selectImage) {
            window?.rtAPI?.selectImage(image);
        }
    }

    const changeImageCreationStatus = (status) => {
        setImageCreationStatus(status)
    }

    const openImageSelectionView = () => {
        if (window?.rtAPI?.openImageSelectionView) {
            window?.rtAPI?.openImageSelectionView();
        }
    }

    return (
        <ElectronContext.Provider value={
            {
                toggleImageGallery,
                openCreateNewImage,
                switchToSignIn,
                switchToApp,
                requestCurrentUserRefresh,
                handleCurrentUserRefresh,
                sendAccessToken,
                requestNewImage,
                selectImage,
                imageCreationStatus,
                changeImageCreationStatus,
                openImageSelectionView,
            }
        }>
            {children}
        </ElectronContext.Provider>
    );
}