import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import useUserImages from "../hooks/UseUserImages";
import Carousel from "react-simply-carousel";
import "../styles/user-image-gallery.css";
import {useElectron} from "../contexts/electronContext";

const UserImageGallery = () => {
    const params = useParams();
    const state = useUserImages(params.userId);
    const [activeSlide, setActiveSlide] = useState(0);
    const { toggleImageGallery } = useElectron();

    if (state.isLoading) {
        return (
            <div className="user-image-gallery">
                <div className="users-loading">
                </div>
            </div>
        );
    }

    if (!state.images || state.isError) {
        return (
            <div className="user-image-gallery">
                <div className="users-empty">No images found</div>
            </div>
        );
    }

    const onShare = () => {
        try {
            const url = state.images[activeSlide].url;

            navigator.clipboard.writeText(url).then(r => {
                console.log('copied');
            });
        } catch (e) {
            console.log(e);
        }
    }

    const onClose = () => {
        toggleImageGallery();
    }

    return (
        <>
            <div onClick={onShare}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="gallery-share-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                </svg>
            </div>
            <div onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="gallery-close-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <Carousel
                centerMode={true}
                disableSwipeByMouse={true}
                disableSwipeByTouch={true}
                activeSlideIndex={activeSlide}
                onRequestChange={setActiveSlide}
                itemsToShow={1}
                speed={250}
                forwardBtnProps={{
                    className: "gallery-forward-btn",
                    children:
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="gallery-arrow-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    ,
                }}
                backwardBtnProps={{
                    className: "gallery-backward-btn",
                    children:
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="gallery-arrow-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    ,
                }}
                containerProps={{
                    className: "gallery-container",
                }}
                activeSlideProps={{
                    className: "gallery-active-slide",
                }}
            >
                {state.images.map((image, index) => (
                    <div key={index} className="gallery-slide">
                        <img src={image.url} alt="TODO" className="gallery-item"/>
                    </div>
                ))}
            </Carousel>
        </>
    );
};

export default UserImageGallery;