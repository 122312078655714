import React from "react";
import "../styles/view-images-page.css";
import UserImageGallery from "../components/UserImageGallery";

const ViewImagesPage = () => {
    return (
        <div className="view-images-page">
            <UserImageGallery />
        </div>
    );
}

export default ViewImagesPage;