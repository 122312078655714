import ApiClient from "./apiClient";

class RtClient extends ApiClient {
    // Users - API calls the users endpoint
    users = {
        // me - get the current user
        me: () => {
            const endpoint = "/users/me"

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
        // getAll - get all users
        getAll: () => {
            const endpoint = "/users"

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
        // get - get a user by id
        getByID: (id) => {
            const endpoint = `/users/${id}`

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
        // images - get all images for a user
        images: (id) => {
            const endpoint = `/users/${id}/images`

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        }
    };

    // Images - API calls the images endpoint
    images = {
        // getByID - get an image by id
        getByID: (id) => {
            const endpoint = `/images/${id}`

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
        // create - create a new image for the user
        create: (payload) => {
            const endpoint = `/images/create`

            return this.jsonRequest({
                method: "POST",
                endpoint: endpoint,
                body: payload,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
        // select - select an image by id - The image must be owned by the current user
        select: (id) => {
            const endpoint = `/images/${id}/select`

            return this.jsonRequest({
                method: "POST",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
    };

    // Jobs - API calls the jobs endpoint
    jobs = {
        // getAll - get job by id
        getByID: (id) => {
            const endpoint = `/jobs/${id}`

            return this.jsonRequest({
                method: "GET",
                endpoint: endpoint,
            }).then((data) => {
                return data;
            }).catch((error) => {
                throw error;
            });
        },
    };
}

export default RtClient;