import React, {useState} from "react";
import "../styles/create-image-form.css";
import { TagsInput } from "react-tag-input-component";
import {useElectron} from "../contexts/electronContext";

const CreateImageForm = ({
                             onCancel,
                             onSubmit,
                             prevPos = [],
                             prevNeg = [],
                             prevStyle = [],
                             prevMan = ""
                         }) => {
    const { requestNewImage } = useElectron();
    const [positiveTags, setPositiveTags] = useState(prevPos);
    const [negativeTags, setNegativeTags] = useState(prevNeg);
    const [styleTags, setStyleTags] = useState(prevStyle);
    const [manualPrompt, setManualPrompt] = useState(prevMan);

    const handleCancel = () => {
        onCancel();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = {
            type: "tags",
            tags: {
                positive: positiveTags,
                negative: negativeTags,
                style: styleTags,
            },
            manual_params: {
                positive_prompt: manualPrompt,
            },
            image_count: 4,
        };

        onSubmit(payload);

        if (typeof requestNewImage === "function") {
            // Electron handler defined, using it.
            console.log("calling requestNewImage");
            requestNewImage(payload);
        }
    }

    const onPositiveTagBlur = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            setPositiveTags([...positiveTags, value]);
            e.target.value = "";
        }
    }

    const onNegativeTagBlur = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            setNegativeTags([...negativeTags, value]);
            e.target.value = "";
        }
    }

    const onStyleTagBlur = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            setStyleTags([...styleTags, value]);
            e.target.value = "";
        }
    }

    const separators = [",", ";", "Enter"];

    return (
        <div className="create-image-container">
            <div className="create-image-header">
                <h1>Create Your Image</h1>
            </div>

            <form id="image-generation-form" onSubmit={handleSubmit}>
                <fieldset id="tags-container">
                    <label htmlFor="positive-tags"><span className="title">Positive Tags</span><span className="description">Include items or themes you'd like to appear in your image</span></label>
                    <TagsInput
                        id="positive-tags"
                        value={positiveTags}
                        onChange={setPositiveTags}
                        onBlur={onPositiveTagBlur}
                        separators={separators}
                        placeHolder="e.g., mountains, sunset"
                    />

                    <label htmlFor="negative-tags"><span className="title">Negative Tags</span><span className="description">Specify items or themes you want to avoid in your image</span></label>
                    <TagsInput
                        id="negative-tags"
                        value={negativeTags}
                        onChange={setNegativeTags}
                        onBlur={onNegativeTagBlur}
                        separators={separators}
                        placeHolder="e.g., cars, cityscape, weird hands"
                    />

                    <label htmlFor="style-tags"><span className="title">Style Tags</span><span className="description">Define the overall aesthetics of your image</span></label>
                    <TagsInput
                        id="style-tags"
                        value={styleTags}
                        onChange={setStyleTags}
                        onBlur={onStyleTagBlur}
                        separators={separators}
                        placeHolder="e.g., minimalistic, vintage, futuristic, watercolor, cartoon, oil painting, photorealistic"
                    />
                </fieldset>
                <span className="or">OR</span>
                <fieldset id="manual-prompt-container">
                    <label htmlFor="manual-prompt"><span className="title">Prompt</span><span className="description">Enter a detailed description of your image</span></label>
                    <textarea id="manual-prompt" name="manual-prompt" rows="4" cols="50" value={manualPrompt} onChange={(e) => setManualPrompt(e.target.value)} placeholder="e.g., A serene lakeside view with a wooden cabin surrounded by tall pine trees at sunset, rendered in a watercolor style." />
                </fieldset>
                <div className="buttons">
                    <button type="submit" id="generate-image">Generate Image</button>
                    <button type="button" id="cancel" onClick={handleCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default CreateImageForm;