import { useState, useEffect } from 'react';

const useIntervalHook = (hook, seconds) => {
    const [reload, setReload] = useState(0);
    const hookState = hook(reload);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setReload(reload + 1);
        }, seconds * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [reload, seconds]);

    return hookState;
};

export default useIntervalHook;
