import React, {useEffect} from 'react';
import Frame from "./Frame";
import "../styles/side-bar.css"
import {useOktaAuth} from "@okta/okta-react";
import {useElectron} from "../contexts/electronContext";

const SideBar = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const { switchToSignIn, switchToApp } = useElectron();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        if (oktaAuth && authState) {
            if (!authState.isAuthenticated) {
                switchToSignIn();
                oktaAuth.signInWithRedirect();
            } else {
                switchToApp();
                setLoading(false);
            }
        }
    }, [oktaAuth, authState]);

    if (loading) {
        return (
            <div className="vertical_container">
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className="vertical_container">
            <Frame/>
        </div>
    );
}

export default SideBar;