import React from "react";
import Users from "./Users";
import You from "./You";
import "../styles/frame.css";

function Frame() {
    return (
        <div className="frame">
            <You />
            <Users />
        </div>
  );
}

export default Frame;