class ApiClient {
    constructor({ baseURL, headers, accessToken }) {
        this.baseURL = baseURL;
        this.headers = headers;
        this.accessToken = accessToken;
    }

    getAccessToken = () => {
        return this.accessToken;
    }

    setAccessToken = (accessToken) => {
        this.accessToken = accessToken;
    }

    // request is a wrapper around fetch requests
    async request({ method, endpoint, body, customHeaders }) {
        const options = {
            method,
            headers: {
                ...this.headers,
                "Authorization": "Bearer " + this.accessToken,
                ...customHeaders,
            },
        };

        if (body) {
            options.body = body;
        }

        return await fetch(`${this.baseURL}${endpoint}`, options);
    }

    // jsonRequest is a wrapper around request that sets the Content-Type header
    async jsonRequest({ method, endpoint, body, customHeaders = {} }) {
        const options = {
            method,
            headers: {
                ...this.headers,
                ...customHeaders,
                "Authorization": "Bearer " + this.accessToken,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${this.baseURL}${endpoint}`, options);
        return await response.json();
    }
}

export default ApiClient;