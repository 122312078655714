import React from "react";
import "../styles/you.css"
import { useElectron } from "../contexts/electronContext";
import {useCurrentUser} from "../contexts/currentUserContext";

const You = () => {
    const { toggleImageGallery, openCreateNewImage, imageCreationStatus, openImageSelectionView, changeImageCreationStatus } = useElectron();
    const { currentUser } = useCurrentUser();

    const galleryOnClick = () => {
        toggleImageGallery(currentUser);
    };

    const createNewImageOnClick = () => {
        openCreateNewImage();
    }

    if (currentUser === null) {
        return (
            <div className="you">
                <div className="you-action-buttons"></div>
                <div id="you-tile">
                    <div className="you-loading-image">
                        <span className="spinner"></span>
                    </div>
                </div>
            </div>
        );
    }

    const loadingButton = (
        <button className="you-action-button-loading">
            <span className="button-spinner"></span>
        </button>
    );

    const newButton = (
        <button className="you-action-button" onClick={createNewImageOnClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="you-action-button-glyph">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        </button>
    );

    const readyOnClick = () => {
        openImageSelectionView();
        changeImageCreationStatus(null);
    }

    const readyButton = (
        <button className="you-action-button-ready" onClick={readyOnClick}>
            <div className="cirlce1"></div>
            <div className="cirlce2"></div>
            <div className="cirlce3"></div>
            <div className="cirlce4"></div>
        </button>
    );

    return (
        <div className="you">
            <div className="you-action-buttons">
                {imageCreationStatus === null && newButton}
                {imageCreationStatus?.status === "waiting" && loadingButton}
                {imageCreationStatus?.status === "selecting" && readyButton}
                <button className="you-action-button" onClick={galleryOnClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="you-action-button-glyph">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </button>
            </div>
            <div className="image-gradient-overlay" id="you-tile"></div>
            <img className="you-current-image" src={currentUser?.current_image?.url} alt="This is you!"/>
            <span className="user-name image-text">You</span>
        </div>
    )
};

export default You;