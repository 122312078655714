import React, { useState, useEffect } from "react";
import {useRtClient} from "../contexts/rtClientContext";

const useUsersMe = (reload) => {
    const {client, isReady} = useRtClient();
    const [state, setState] = useState({
        currentUser: null,
        isLoading: true,
        isError: false,
    });

    useEffect(() => {
        if (isReady && client) {
            const abortController = new AbortController();

            const fetchMe = async () => {
                try {
                    const me = await client.users.me({signal: abortController.signal});
                    setState({
                        currentUser: me.user,
                        isLoading: false,
                        isError: false,
                    });
                } catch (err) {
                    if (err.name !== "AbortError") {
                        setState({
                            currentUser: null,
                            isLoading: false,
                            isError: true,
                        });
                    }
                }
            };

            // Intentionally ignoring the promise
            fetchMe();

            return () => {
                abortController.abort();
            };
        }
    }, [client, isReady, reload]);

    return state;
}

export default useUsersMe;