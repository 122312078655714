import React from "react";
import "../styles/choose-image.css";
import {useElectron} from "../contexts/electronContext";

const ChooseImage = () => {
    const { imageCreationStatus, openCreateNewImage, selectImage, changeImageCreationStatus } = useElectron();

    const onEditPrompt = () => {
        changeImageCreationStatus(null);
    }

    return (
        <div className="choose-image">
            <div className="image-grid">
                { imageCreationStatus.images?.map((image) => {
                    const onClick = () => {
                        selectImage(image);
                    };

                    return (
                        <div className="image-tile">
                            <img className="image" key={image.id} src={image.url} alt={image.id} onClick={onClick} />
                        </div>
                    );
                })}
            </div>
            <div className="choose-image-actions">
                <button className="choose-image-action" onClick={onEditPrompt}>Edit Prompt</button>
                <button className="choose-image-action" onClick={openCreateNewImage}>Cancel</button>
            </div>
        </div>
    );
};

export default ChooseImage;