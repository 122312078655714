import React, { useState } from "react";
import "../styles/user.css"
import {useTimestamp} from "../hooks/UseTimestamp";
import getTimeInTimeZone from "../utils";
import {useElectron} from "../contexts/electronContext";

function User({ user }) {
    const timestamp = useTimestamp();
    const currentImage = user?.current_image;
    const params = currentImage?.parameters;
    const { toggleImageGallery } = useElectron();

    const galleryOnClick = () => {
        toggleImageGallery(user);
    };

    return (
      <div className="user" key={user.id} onClick={galleryOnClick}>
            <div className="image-gradient-overlay"></div>
            <img className="user-current-image animated-image" src={currentImage?.url} alt={params?.prompt}/>
            <span className="user-name image-text">{user?.first_name}</span>
            <span className="user-time image-text">{getTimeInTimeZone(user?.location?.timezone, timestamp)}</span>
            <span className="user-location image-text">{params?.city}, {params?.short_state}</span>
            <span className="user-temp image-text">{params?.temp}°</span>
      </div>
  );
}

export default User;