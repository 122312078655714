import React from 'react';
import '../styles/app.css';
import {OktaAuth} from "@okta/okta-auth-js";

import {Security, LoginCallback} from "@okta/okta-react";
import {RtClientProvider} from "../contexts/rtClientContext";
import {CurrentUserProvider} from "../contexts/currentUserContext";
import {ElectronProvider} from "../contexts/electronContext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import SideBar from "./SideBar";
import ViewImagesPage from "../pages/ViewImagesPage";
import CreateImagePage from "../pages/CreateImagePage";

const App = () => {
    const oktaAuth = new OktaAuth({
        issuer: process.env.REACT_APP_OKTA_AUTH_ISSUER,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
        redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
        responseType: ['token', 'id_token', 'code'],
        scopes: ['openid', 'profile', 'email', 'offline_access'],
        services: {
            tokenService: {
                autoRenew: true,
                autoRemove: true,
                expireEarlySeconds: 45 * 60,
            },
        },
        tokenManager: {
            expireEarlySeconds: 45 * 60,
        },
        devMode: true,
    });



    oktaAuth.start().then(() => {
        console.log("oktaAuth started");

        oktaAuth.tokenManager.on('expired', async (key, expiredToken) => {
            console.log(`Token: ${key} expired`);
        });
    });



    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        window.location.assign(window.location.origin + "/");
    }

    const AppView = ({ child }) => {
        return (
            <ElectronProvider>
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                    <RtClientProvider>
                        <CurrentUserProvider>
                            {child}
                        </CurrentUserProvider>
                    </RtClientProvider>
                </Security>
            </ElectronProvider>
        );
    };

    const BareView = ({ child }) => {
        return (
            <ElectronProvider>
                {child}
            </ElectronProvider>
        );
    };

    const router = createBrowserRouter([
        { path: "/", element: <AppView child={<SideBar/>} /> },
        { path: "login/callback", element: <AppView child={<LoginCallback />} /> },
        { path: "image-gallery/:userId", element: <AppView child={<ViewImagesPage />} /> },
        { path: "new-image", element: <BareView child={<CreateImagePage/>} /> },
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default App;
