import React, {createContext, useContext, useEffect, useState} from "react";
import RtClient from "../services/rtClient";
import {useOktaAuth} from "@okta/okta-react";
import {useElectron} from "./electronContext";

const RtClientContext = createContext({
    client: null,
    isReady: false,
});

export const useRtClient = () => {
    return useContext(RtClientContext);
};

export const RtClientProvider = ({children}) => {
    const { sendAccessToken } = useElectron();
    const { oktaAuth, authState } = useOktaAuth();
    const [client, setClient] = useState(null);
    const [isReady, setIsReady] = useState(false);

    const setupClient = (accessToken) => {
        const rtClient = new RtClient({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            accessToken: accessToken,
        });
        setClient(rtClient);
        setIsReady(true);
    }

    useEffect(() => {
        if (oktaAuth && authState) {
            if (authState.isAuthenticated) {
                let accessToken = oktaAuth.getAccessToken()

                if (accessToken) {
                    setupClient(accessToken);
                    sendAccessToken(accessToken);
                }
            }
        }
    }, [oktaAuth, authState, sendAccessToken]);

    return (
        <RtClientContext.Provider value={{client, isReady}}>
            {children}
        </RtClientContext.Provider>
    );
}