import User from "./User";
import React from "react";
import useIntervalHook from "../hooks/UseIntervalHook";
import useUsers from "../hooks/UseUsers";
import "../styles/users.css";

const Users = () => {
    const { users, isLoading, isError } = useIntervalHook(useUsers, 60);

    if (isLoading) {
        return (
            <div className="users">
                <div className="users-loading">
                </div>
            </div>
        );
    }

    if (!users || isError) {
        return (
            <div className="users">
                <div className="users-empty">
                </div>
            </div>
        );
    }

    return (
        <div className="users">
            {users.map((user) => (
                <User key={user.id} user={user}/>
            ))
        }
        </div>
    );
}

export default Users;