import React, {useEffect, useState} from "react";
import "../styles/create-image-page.css";
import CreateImageForm from "../forms/CreateImageForm";
import ChooseImage from "../components/ChooseImage";
import { useElectron } from "../contexts/electronContext";

const CreateImagePage = () => {
    const { openCreateNewImage, imageCreationStatus } = useElectron();
    const [prevPos, setPrevPos] = useState([]);
    const [prevNeg, setPrevNeg] = useState([]);
    const [prevStyle, setPrevStyle] = useState([]);
    const [prevMan, setPrevMan] = useState("");


    // TODO - Use a reduce to manage the image creation state

    const onCancel = () => {
        // Toggle the create new image window
        openCreateNewImage();
    }

    const onSubmit = (payload) => {
        setPrevPos(payload.tags.positive);
        setPrevNeg(payload.tags.negative);
        setPrevStyle(payload.tags.style);
        setPrevMan(payload.manual_params.positive_prompt);
    }


    return (
        <div className="create-image-page">
            { imageCreationStatus === null && <CreateImageForm
                onCancel={onCancel}
                onSubmit={onSubmit}
                prevPos={prevPos}
                prevNeg={prevNeg}
                prevStyle={prevStyle}
                prevMan={prevMan}
            /> }
            { imageCreationStatus?.status === "selecting" && <ChooseImage /> }
        </div>
    );
}

export default CreateImagePage;